import React from "react";
import Card from "react-bootstrap/Card";

const Data = ({ data }) => {
    const Data = data.map((item, i) => {
        return (
            <div
                className='col-lg-4 col-md-4 col-sm-12'
                key={i}
                style={{ marginBottom: "10px" }}
            >
                <a href={item.image} download>
                    {/* <Card className='text-center'>
                        <Card.Img variant='top img-fluid' src={item.image} />
                    </Card> */}
                    <img
                        className="rounded"
                        src={item.image}
                        alt={i}
                        style={{ width: "500px", height: "300px" }}
                    />
                </a>
            </div>
        );
    });
    return Data;
};

export default Data;
